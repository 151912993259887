'use client';

import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { CheckIcon, Loader2, Minus } from 'lucide-react';
import { cn } from 'mxcn';
import { useFieldMaybe } from './field/use-field';
import { useState } from 'react';
import { Overwrite } from '@/lib/types';
export const checkboxClassName = ['peer size-4 shrink-0 rounded border transition-all border-contrast-300 shadow ring-2 ring-transparent', 'hover:border-contrast-400', 'focus-visible:outline-none focus-visible:ring-focus-700/30', 'not-[aria-readonly]:disabled:cursor-not-allowed not-[aria-readonly]:disabled:opacity-50', 'data-[state=checked]:bg-focus-500 data-[state=checked]:text-gray-50 data-[state=checked]:border-focus-700', 'data-[state=indeterminate]:bg-focus-300 data-[state=indeterminate]:text-gray-50 data-[state=indeterminate]:border-focus-400'].join(' ');
function Checkbox({
  className,
  ref,
  ...props
}: Overwrite<React.ComponentPropsWithRef<typeof CheckboxPrimitive.Root>, {
  onCheckedChange?: (checked: boolean) => void | Promise<void>;
}>) {
  const field = useFieldMaybe<boolean>();
  const [busy, setBusy] = useState(false);
  if (field && !('checked' in props)) {
    props.checked = field.value;
    props.id ??= field.id;
    props.name ??= field.name;
    const {
      onCheckedChange
    } = props;
    props.onCheckedChange = checked => {
      field.onValueChange(Boolean(checked));
      field.onBlur();
      return onCheckedChange?.(checked);
    };
  }
  const {
    onCheckedChange
  } = props;
  props.onCheckedChange = checked => {
    const result = onCheckedChange?.(checked);
    if (result?.then) {
      setBusy(true);
      result.finally(() => {
        setBusy(false);
      });
    }
    return result;
  };
  return <CheckboxPrimitive.Root ref={ref} className={cn(checkboxClassName, className)} {...props} defaultChecked={props.defaultChecked ?? field?.value} data-sentry-element="unknown" data-sentry-component="Checkbox" data-sentry-source-file="checkbox.tsx">
      <CheckboxPrimitive.Indicator className="flex items-center justify-center text-current" data-sentry-element="unknown" data-sentry-source-file="checkbox.tsx">
        {busy ? <Loader2 className="h-3 w-3 animate-spin" /> : props.checked === 'indeterminate' ? <Minus className="h-3 w-3" strokeWidth={2.5} /> : <CheckIcon className="h-3 w-3" strokeWidth={2.5} />}
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>;
}
export { Checkbox };